.marquee-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.marquee-container {
    width: 100%;
    white-space: nowrap;
    position: relative;
    margin-bottom: 10rem;
    overflow: hidden;
    margin-left: 0%;
}
  
.marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
    animation-delay: -12.5s;
    animation-play-state: running;
}

.marquee2 {
    animation-delay: -7.5s !important;
}
  
.marquee-item {
    display: inline-block;
    padding: 0 .25em;
    font-size: 8vw;
    font-family: "Merienda", cursive;
}

.marquee-wrapper::before,
.marquee-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  pointer-events: none;
  z-index: 1;
}

.marquee-wrapper::before {
  left: 0;
  background: linear-gradient(to right, rgb(11, 11, 11), rgba(255, 255, 255, 0));
}

.marquee-wrapper::after {
  right: 0;
  background: linear-gradient(to left, rgb(11, 11, 11), rgba(255, 255, 255, 0));
}
  
@keyframes marquee {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-100%, 0, 0) }
}
 
@media only screen and (max-width: 768px) {
    .marquee-wrapper {
        width: calc(100% + 80px);
        margin-left: -40px;
    }
    .marquee-item {
        font-size: 18vw;
    }
}

@media only screen and (min-width: 1400px) {

}