
.aboutHero {
    padding-top: 6vw;
}
.aboutHero > h1 {
    text-align: center;
}
.aboutHeroName {
    font-family: "Merienda", cursive;
    font-size: 5vw;
    font-style: italic;
}

.aboutHeadText {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 6vh;
    padding: 4vh 8vw 6vh;
    overflow: hidden;
}

.aboutParagraphs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-width: 100%;
}
.aboutParagraphs > p {
    font-size: 20px;
}

.aboutImg {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    position: relative;
}
.aboutImg > img {
    height: 100%; 
    object-fit: cover;
    box-sizing: border-box;
}

.selfPortrait {
    border: 7px double var(--accent-color);
    border-radius: 999px;
    max-width: 300px; 
    position: relative;
    z-index: 10;
}

.aboutBuffer {
    border-bottom: 1px solid var(--accent-color);
    border-top: 1px solid var(--accent-color);
    height: 25px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    border-radius: 999px;
}
.aboutBuffer > canvas {
    opacity: .5;
}

.aboutBottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 40px;
}

.aboutLinks {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping of child elements */
    gap: 20px; /* Optional: Add space between items */
    align-items: center;
}
  
.aboutLinks a {
    flex: 1 1 0; /* Allow wrapping and make each link take up 50% of the width, minus gap */
    box-sizing: border-box; /* Include padding and border in the element’s total width and height */
    text-align: center; /* Center the text inside the link */
    padding: 10px; /* Add padding for better appearance */
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    text-decoration: none;
    font-family: "Merienda", cursive;
}
.aboutLinks > a:hover {
    border-radius: 10px;
    background-color: rgba(34, 46, 57, 0.5);
    box-shadow: 0 0 2px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255), 0 0 5px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
}

.aboutFooter {
    padding: 0 8vw;
}
.aboutFooter > h2 {
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .aboutHero > h1 {
        font-size: 30px;
    }
    .aboutHeadText {
        flex-direction: column;
        padding: 4vh 0vw 6vh;
    }
    .aboutParagraphs {
        max-width: 100%;
    }
    .aboutImg > img {
        border-radius: 999px 999px 10px 10px;
        width: 100%; 
    }
    .aboutLinks {
        justify-content: space-between;
        padding: 0;
        font-size: 4vw;
    }
    .aboutFooter {
        padding: 0;
    }
    .aboutBuffer {
        display: none;
    }
}