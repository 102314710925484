.homeHead {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10rem;
}

.headText {
    flex: 1;
    max-width: 50%;
    text-align: center;
}

.headText > h1 {
    font-size: 8vw;
    -webkit-text-stroke: 1px var(--accent-color);
    font-family: "Merienda", cursive;
    
}
.headText > p {
    font-size: 2vw;
    padding-left: 4px;
}

.headImg {
    flex: 1;
    position: relative;
}

.scrollPageCont {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}
.scrollPageLink {
    position: relative;
    transition: filter .25s ease-in-out;
}
.scrollPageLink:hover {
    filter: grayscale(100%) brightness(200%);
    width: 70px;
}
.scrollPageImg {
    width: 60px;
}
.arrow {
    position: absolute;
    left: 0;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 6s linear infinite;
    -moz-animation: rotating 6s linear infinite;
    -ms-animation: rotating 6s linear infinite;
    -o-animation: rotating 6s linear infinite;
    animation: rotating 6s linear infinite;
}

.projectsCont {
    margin-bottom: 10rem;
}

.homeContact > h2 {
    font-size: 52px;
    margin-bottom: 20px;
}
.homeContact > button {
    color: var(--accent-color);
    background-color: transparent;
    border: none;
    font-size: 52px;
    font-family: "Merienda", cursive;
    font-style: italic;
    cursor: pointer;
    transition: all .25s ease-in;
}
.homeContact > button:hover {
    color: white;
}


@media only screen and (max-width: 768px) {
    .homeHead {
        flex-direction: column;
        padding-bottom: 2rem;
        margin-bottom: 0;
    }
    .headText {
        text-align: center;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .headText > h1 {
        font-size: 18vw;
    }
    .headText > p {
        font-size: 4.5vw;
        padding-left: 0px;
    }
    .scrollPageCont {
        display: none;
    }
}