.projectsHead {
    text-align: center;
    padding: 2vh 0 4vh 0;
}
.projectsHead > h1 {
    font-size: 5vw; 
    font-family: "Merienda", cursive;
    margin-bottom: 10px;  
}
.projectsHead > p {
    font-size: 20px;
}

.projectsPageList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
}

.projectPageItem {
    flex: 1 1 auto;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: rgba(34, 46, 57, 0.5);
    border-radius: 7px;
    padding: 15px;
}

.projectItemHead {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.projectItemHead > h2 {
    font-size: 28px;
    font-family: "Merienda", cursive;  
    margin-top: 10px;
    
}
.projectItemHead > a {
    color: var(--accent-color);
    margin-top: 10px;
    text-decoration: none;
}
.projectItemHead > a:hover {
    text-decoration: underline;
}

.projectPageImg {
    display: flex;
    width: 100%;  
    height: 100%;
    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
}
.projectPageImg > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.projectPageTagList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.projectPageTag {
    border: 1px solid var(--accent-color);
    border-radius: 999px;
    padding: 5px 10px;
    font-style: italic;
    font-size: 12px;
}

@media only screen and (max-width: 767px) {
    .projectsHead > h1 {
        font-size: 30px; 
    }
    .projectPageItem {
        max-width: 100%;
    }
    .projectPageItem > h2 {
        font-size: 24px;
    }
    .projectPageTagList {
        display: none;
    }
    .projectsPageList {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        gap: 80px;
    }
    .projectItemHead {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
    }
    .projectItemHead > a {
        text-decoration: underline;
    }
}