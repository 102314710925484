.projectsCont > h2 {
    margin-bottom: 40px;
    font-size: 52px;
}

.projectsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.projectItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--accent-color);
    padding: 10px;
    cursor: pointer;
    background-color: rgba(13, 13, 13, .5);
    transition: all .1s ease-in-out;
}
.projectItem:hover {
    background-color: rgba(34, 46, 57, 0.5);
    border-radius: 10px;
    box-shadow: 0 0 2.5px rgb(255, 255, 255), 0 0 5.5px rgb(255, 255, 255), 0 0 9.5px rgb(255, 255, 255), 0 0 12px rgb(255, 255, 255);
}
.projectItem > h2 {
    font-size: 2.5vw;
    font-family: "Merienda", cursive;
}

.projectTagList {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.projectTag {
    border: 1px solid var(--accent-color);
    border-radius: 999px;
    padding: 5px 10px;
    font-style: italic;
}

.projectImg {
    position: absolute;
    left: 50%;
    width: 300px;
    transform: translate(-50%);
}
.projectImg > img {
    width: 100%;
    border: 2px solid var(--accent-color);
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .projectItem {
        flex-direction: column;
        align-items: flex-start;
    }
    .projectItem > h2 {
        font-size: 6vw;
        margin-bottom: 6px;
    }
    .projectTag {
        font-size: 12px;
        padding:.4em .6em;
    }
    .projectTagList {
        flex-wrap: wrap;
    }
    .projectsCont > p {
        margin-bottom: 10px;
        font-size: 4vw;
    }
}