.contactCont {
    padding: 0vh 2vw;
    margin-bottom: 2.5rem;
}

.contactHead {
    text-align: center;
    padding: 2vh 0 4vh 0;
}
.contactHead > h1 {
    font-size: 5vw;
    font-family: "Merienda", cursive;
}
.contactHead > p {
    font-size: 20px;
}

#subContainer {
    display: flex;
    flex-direction: column;
}

.submission {
    text-align: center;
    border: 7px double #f2aa4cff;
    background: rgb(0, 0, 0, 1);
    padding: 2em;
    margin: 0 auto 0 auto;
    width: 60%;
}
  
#contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 100;
}

input {
    background-color: rgba(34, 46, 57, 0.65);
    width: 100%;
    border: 3px solid black;
    border-radius: 15px;
    margin: 0 auto 2em auto;
    padding: 1em 0em 1em 1em;
    color: white;
}
  
textarea {
    background-color: rgba(34, 46, 57, 0.65);
    border: 3px solid black;
    border-radius: 15px;
    width: 100%;
    height: 8em;
    margin: 0 auto 2em auto;
    padding: 1em 0em 1em 1em;
    color: white;
    resize: none;
}
  
input[type=submit] {
    font-family: "Merienda", cursive;
    background-color: rgba(34, 46, 57, 1);
    color: #f2aa4cff;
    font-size: 1.5em;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    width: 100%;
}
input[type=submit]:hover {
    background-color: rgba(34, 46, 57, .5);
}
input[type=submit]:active {
    background-color: rgba(34, 46, 57, .25);
}
  
label {
    color: #FF7F7F;
}
  
.inputError {
    background-color: rgb(16, 24, 32, 0.5);
    width: 100%;
    border: 3px solid #FF7F7F;
    border-radius: 15px;
    margin: 0em auto 0em auto;
    padding: 1em 0em 1em .5em;
    color: white;
}

@media only screen and (max-width: 767px) {
    .contactCont {
        padding: 0;
    }
    .contactHead > h1 {
        font-size: 30px;
    }
    .submission {
        width: 100%;
    }
    .contactHead {
        text-align: center;
        padding: 2vh 0 6vh 0;
    }
}