:root {
  --bg-color: rgb(13, 13, 13);
  --accent-color: rgb(242, 170, 76);
}

.App {
  position: relative;
  color: var(--accent-color);
  box-sizing: border-box;
  min-height: 100vh;
}

.AppContent {
  position: relative;
  min-height: 100vh;
  z-index: 10;
  padding: 0 40px;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 5rem;
}

.sketchCont {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 5%;
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
