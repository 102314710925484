.navLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 40px;
    
}
.navLinks > button {
    background-color: transparent;
    color: var(--accent-color);
    font-size: 1vw;
    border: none;
    font-family: "Nanum Gothic", sans-serif;
    cursor: pointer;
}
.navLinks > button:hover {
    color: white;
}

@media only screen and (max-width: 768px) {
    .navLinks {
        padding: 40px 0;
    }
    .navLinks > button {
        font-size: 4vw;
    }
}